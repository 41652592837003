import $ from 'jquery';
import 'jquery.cookie';

$(document).ready(function() {
  let addCookie = $('#addCookies'),
    delCookie = $('#delCookies'),
    numberCookies = $('#cookies'),
    widget;

  let widgetsData = $.cookie('widgets');
  if (!widgetsData) {
    widgetsData = {};
  } else {
    widgetsData = JSON.parse(widgetsData);
  }
  console.log(widgetsData);
  $('.publication-chain').each(function() {
    let e = $(this).data('widget');
    if (!widgetsData[e]) {
      widgetsData[e] = 0;
      $.cookie('widgets', JSON.stringify(widgetsData));
    }
    setActualNumber(e, widgetsData[e]);
  });

  //*
  //* при загрузке страницы
  //*
  function setActualNumber(widgetName, publicationNumber) {
    let actualPublication = $('*[data-widget=' + widgetName + ']')
      .children('.publication-chain__blocks')
      .children('.publication-block')
      .eq(publicationNumber);

    let publicationLink = actualPublication.attr('data-link'); // ссылка на страницу с актуальной публикацией

    // ставим актуальную ссылку виджету
    $('*[data-widget=' + widgetName + ']').prop('href', publicationLink);
    // добавляем ховер актуальной публикации
    actualPublication.addClass('next');
    // класс done всем прочитанным публикациям
    actualPublication
      .siblings(':lt(' + publicationNumber + ')')
      .addClass('done');
  }

  //*
  //* чтение публикаций
  //*
  addCookie.on('click', function() {
    widgetsData[widgetName] = widgetsData[widgetName];

    $.cookie('widgets', JSON.stringify(widgetsData));
    publicationLink = $('.publication-block')
      .eq(widgetsData[widgetName])
      .attr('data-link');

    // убираем класс next у прочитанного блока и переносим класс следующему
    $('.publication-chain__blocks')
      .find('.next')
      .removeClass('next')
      .addClass('done')
      .next()
      .addClass('next');

    // меняем ссылку у виджета
    $('.publication-chain__blocks').prop('href', publicationLink);
  });

  //*
  //* чистим куки
  //*
  delCookie.on('click', function() {
    $.cookie('widgets', null);

    $('.publication-chain__blocks')
      .find('.next')
      .removeClass('next');
    $('.publication-chain__blocks')
      .find('.done')
      .removeClass('done');
    $('.publication-chain__blocks')
      .children()
      .first()
      .addClass('next');
  });

  $('.publication-chain').on('click', function() {
    let e = $(this).data('widget');
    $.cookie('activeWidget', e);
  });

  $(window).on('scroll', function(event) {
    if ($(window).scrollTop() + $(window).height() >= $(document).height()) {
      console.log('ура! конец страницы!');
      let activeWidget = $.cookie('activeWidget', { path: '/' });
      let allWidgets = JSON.parse($.cookie('widgets'));
      allWidgets[activeWidget] += 1;
      console.log(allWidgets);
      $.cookie('widgets', JSON.stringify(allWidgets), { path: '/' });

      $(window).off('scroll');
      $.cookie('activeWidget', null, { path: '/' });
    }
  });
});
