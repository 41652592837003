import $ from 'jquery';
import '../styles/main.scss';
import './widget.js';
import './guesswork.js';

const scriptURL =
  'https://script.google.com/macros/s/AKfycbwVunxoDU5av80bFl0Z3bIaL5F01aa6aU-I8LlHykugTYQu4Zo/exec';

const forms = $('.submit-to-google-sheet');

if (forms.length) {
  forms.submit(e => {
    e.preventDefault();
    var formContainer = $(e.currentTarget).closest('.form-container');

    formContainer.addClass('sending');

    fetch(scriptURL, { method: 'POST', body: new FormData(e.currentTarget) })
      .then(response =>
        formContainer.addClass('success').removeClass('sending')
      )
      .catch(error => formContainer.addClass('error').removeClass('sending'));
  });
}

$('.js-scroll-link').click(function(e) {
  e.preventDefault();

  var linkOffset = -20;
  if ($($.attr(this, 'href')).data('scroll-link-offset')) {
    linkOffset += $($.attr(this, 'href')).data('scroll-link-offset');
  }

  $('html, body').animate(
    {
      scrollTop: $($.attr(this, 'href')).offset().top + linkOffset,
    },
    500
  );
});

$(document).mouseup(function(e) {
  var div = $('.page-header__submenu-drop');
  if (!div.is(e.target) && div.has(e.target).length === 0) {
    div.hide();
  }
  $('.page-header__submenu').on('click', function() {
    div.toggle();
  });
});
