import $ from 'jquery';
$(document).ready(function() {
  let userChain,
    goToStart = $('#goToStart');

  // добавляем выбранность бабблам
  $('.guesswork__main')
    .children()
    .children()
    .on('click', function() {
      $(this).addClass('active');
    });

  // первый шаг
  $('#guessworkStep1')
    .children()
    .on('click', function() {
      userChain = $(this).attr('data-chain');
      console.log(userChain);

      $('.guesswork__indicate').text('2/3');

      $('#guessworkStep1')
        .children('div:not(.active)')
        .hide();

      // показываем второй шаг
      $('#guessworkStep2').css('display', 'flex');

      // показываем элементы выбранной цепочки из второго шага
      $('#guessworkStep2')
        .children()
        .hide();
      $('#guessworkStep2')
        .children('div[data-chain="' + userChain + '"]')
        .show();
    });

  // второй шаг
  $('#guessworkStep2')
    .children()
    .on('click', function() {
      $('.guesswork__indicate').text('3/3');
      $('#guessworkStep2')
        .children('div:not(.active)')
        .hide();

      // показываем третий шаг
      $('#guessworkStep3').css('display', 'flex');

      // показываем элементы выбранной цепочки из второго шага
      $('#guessworkStep3')
        .children()
        .hide();
      $('#guessworkStep3')
        .children('div[data-chain="' + userChain + '"]')
        .show();
    });

  // третий шаг
  $('#guessworkStep3')
    .children()
    .on('click', function() {
      $('.guesswork__main').hide();
      $('.guesswork__result').css('display', 'block');
    });

  // сброс прогресса
  goToStart.on('click', function() {
    $('.guesswork__main')
      .children()
      .hide();
    $('.guesswork__result').hide();
    $('.guesswork__main').css('display', 'block');
    $('.guesswork__indicate').text('1/3');
    $('.guesswork__main')
      .children('#guessworkStep1')
      .show();
    $('.guesswork__main')
      .children()
      .children()
      .removeClass('active')
      .show();
    userChain = 0;
    console.log(userChain);
  });
});
